// lib
import React, { StrictMode, lazy, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ChakraProvider, Spinner, Center, ColorModeScript } from '@chakra-ui/react'
import { Toaster } from 'react-hot-toast'

// store
import { store } from './redux/index.js'

// Theme configuration
import theme from './theme' // We'll create this file next

// Code-split the main App component for better initial load performance
const App = lazy(() => import('./App.jsx'))

/**
 * Main application entry point
 * 
 * Performance optimizations:
 * 1. Code splitting with React.lazy for the main App component
 * 2. Suspense fallback for better user experience during loading
 * 3. StrictMode to catch potential issues in development
 * 
 * Note: The document title is statically defined in index.html
 * and should not be changed by individual components
 */

// Simple loading spinner without theme-dependent values
const SimpleLoadingSpinner = () => (
  <Center h="100vh" w="100vw" bg="white">
    <Spinner size="xl" thickness="4px" color="#4364F7" />
  </Center>
)

// Memory leak prevention by creating the root outside of any functions
const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

// Render the application
root.render(
  <StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <Suspense fallback={<SimpleLoadingSpinner />}>
            <App />
          </Suspense>
          <Toaster position='top-right' />
        </Router>
      </Provider>
    </ChakraProvider>
  </StrictMode>,
)
